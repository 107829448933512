import {useEffect, useState} from 'react'

import Certifications from '../components/certifications/certifications'
import Education from '../components/education/education'
import Skills from '../components/skills/skills'
import WorkExperience from '../components/work-experience/work-experience'

import './resume.css'
import MissionStatement from '../components/mission-statement/mission-statement'
import PersonalProjects from '../components/personal-projects/personal-projects'

const Resume = () => {
	const [isDarkModeOn] = useState(false)

	const darkModeToggleModifier = isDarkModeOn ? '--dark' : ''

	useEffect(() => {
		const backgroundColorProperty = `--main-bg-color${darkModeToggleModifier}`
		document.body.style.backgroundColor = getComputedStyle(document.body).getPropertyValue(backgroundColorProperty)
	}, [darkModeToggleModifier, isDarkModeOn])

	return (
		<main className={`resume${darkModeToggleModifier}`}>
			<header className={`resume__title resume__title${darkModeToggleModifier}`}>
				<h1>Leah Ferrell</h1>
				<a rel="noreferrer" className='resume__link' target='_blank' href='https://www.github.com/leahferrell'>github.com/leahferrell</a>
			</header>
			<MissionStatement isDarkModeOn={isDarkModeOn}/>
			<div className='resume__container'>
				<div className='resume__left-container'>
					<WorkExperience isDarkModeOn={isDarkModeOn}/>
				</div>
				<div className='resume__right-container'>
					<Education isDarkModeOn={isDarkModeOn}/>
					<Certifications isDarkModeOn={isDarkModeOn}/>
					<Skills isDarkModeOn={isDarkModeOn}/>
					<PersonalProjects isDarkModeOn={isDarkModeOn}/>
				</div>
			</div>
		</main>
	)
}

export default Resume
